import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import Customers from "components/ui/extended/Customers";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import ImageHeader from "components/ui/base/ImageHeader";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import TestimonialWithImageLeon from "components/ui/extended/TestimonialWithImageLeon";
import FeatureGrid from "components/ui/base/FeatureGrid";

interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.live-chat-for-mobile-apps.seo.title")}
        description={t("pages.live-chat-for-mobile-apps.seo.description")}
        image={{
          relativePath: "meta/customerly-live-chat-mobile-sdk.jpg",
          alt: "Customerly Mobile SDKs - Give your mobilee customers the support they deserve",
        }}
      />

      <ImageHeader
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Live Chat SDK for mobile apps",
            path: "/live-chat-for-mobile-apps/",
          },
        ]}
        title={t("pages.live-chat-for-mobile-apps.title")}
        description={t("pages.live-chat-for-mobile-apps.description")}
        cta={{
          show: true,
          justifyValidation: "start",
          hideValidationOnXl: true,
        }}
        image={{
          alt: t("pages.live-chat-for-mobile-apps.title"),
          relativePath:
            "pages/live-chat-for-mobile-apps/live-chat-mobile-apps-sdk-chat-ios-android.png",
          maxWidth: 350,
        }}
        circleBackground={{
          show: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat-for-mobile-apps.1.title")}
        description={t("pages.live-chat-for-mobile-apps.1.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-for-mobile-apps/live-chat-for-mobile-apps-sdk-ios-android-live-chat.jpg",
          alt: t("pages.live-chat-for-mobile-apps.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat-for-mobile-apps.2.title")}
        description={t("pages.live-chat-for-mobile-apps.2.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-for-mobile-apps/live-chat-mobile-sdk-integration-code.jpg",
          alt: t("pages.live-chat-for-mobile-apps.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat-for-mobile-apps.3.title")}
        description={t("pages.live-chat-for-mobile-apps.3.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-for-mobile-apps/live-chat-for-mobile-sdk-in-app-campaigns.jpg",
          alt: t("pages.live-chat-for-mobile-apps.3.title"),
          position: "left",
          fullWidth: true,
        }}
        cta={{
          link: "/email-marketing/",
          text: t("pages.live-chat-for-mobile-apps.3.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.live-chat-for-mobile-apps.4.title")}
        description={t("pages.live-chat-for-mobile-apps.4.subtitle")}
        image={{
          relativePath:
            "pages/live-chat-for-mobile-apps/live-chat-for-mobile-apps-with-survey.jpg",
          alt: t("pages.live-chat-for-mobile-apps.4.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: "/surveys/",
          text: t("pages.live-chat-for-mobile-apps.4.cta"),
        }}
      />

      <FeatureGrid
        centered
        bigImages
        title={t("pages.live-chat-for-mobile-apps.apps.title")}
        sections={[
          {
            title: t("pages.live-chat-for-mobile-apps.apps.ios.title"),
            image: {
              alt: "iOS",
              relativePath:
                "pages/live-chat-for-mobile-apps/live-chat-mobile-sdk-for-ios-apps.jpg",
            },
            links: [
              {
                text: t("pages.live-chat-for-mobile-apps.apps.ios.cta"),
                path: "https://github.com/customerly/Customerly-iOS-SDK",
              },
            ],
          },
          {
            title: t("pages.live-chat-for-mobile-apps.apps.android.title"),
            image: {
              alt: "Android",
              relativePath:
                "pages/live-chat-for-mobile-apps/live-chat-mobile-sdk-for-android.jpg",
            },
            links: [
              {
                text: t("pages.live-chat-for-mobile-apps.apps.android.cta"),
                path: "https://github.com/customerly/Customerly-Android-SDK",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageLeon />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const indexPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
